const setCustomCookie = (name, value, minutes) => {
  const now = new Date();
  now.setTime(now.getTime() + minutes * 60 * 1000);
  const expires = `expires=${now.toUTCString()}`;
  const path = `path=/;`;
  const domainInfo = window.location.host.split('.');
  if (!['staging', 'development', 'corporategift'].includes(domainInfo[0])) domainInfo.splice(0, 1);
  let domain = `domain=.${domainInfo.join('.')};`;
  if (window.location.host.includes('localhost')) domain = '';
  document.cookie = `${name}=${value};${domain}${path} ${expires}`;
}

export default setCustomCookie;