<template>
  <v-container class="account-dashboard pa-0">
    <v-row no-gutters class="flex-wrap justify-center pb-16">
      <v-col cols="12" class="account-dashboard__title pt-13 pb-3 px-4 px-md-0">
        {{ (hasCgCredits || hasOrders) ? 'Welcome back' : 'Welcome' }}{{ userName ? `, ${userName}` : '' }}!
      </v-col>

      <div class="account-dashboard__grid">
        <the-account-owner v-if="userIsCgElite && (masterAccount || superAdmin)" />
        <the-account-admin
          v-else-if="userIsCgElite && !masterAccount && !showAccountInfo"
          @showAccountInfo="(val) => showAccountInfo = val"
        />
        <the-account-status
          v-else-if="marketplaceUser && !showAccountInfo"
          @showAccountInfo="(val) => showAccountInfo = val"
        />
        <the-account-info v-else />

        <the-recent-thank-you-notes />

        <the-cg-credits-balance
          v-if="!hideCgCredits"
          :hasApprovalOrders="userHasAccessToApproveOrders"
          :hasInventory="userHasAccessToInventory"
          @hideCgCredits="(val) => hideCgCredits = val"
          @userBalance="(val) => hasCgCredits = !!val"
        />

        <the-active-egift-orders
          :hasCgCredits="!hideCgCredits"
        />

        <the-inventory
          v-if="userIsCgElite && adminAccount && userHasAccessToInventory"
          :hasApprovalOrders="userHasAccessToApproveOrders"
        />

        <the-approval-orders
          v-if="userIsCgElite && adminAccount && userHasAccessToApproveOrders"
          :hasInventory="userHasAccessToInventory"
        />

        <the-recent-orders
          :hasApprovalOrders="userHasAccessToApproveOrders"
          :hasInventory="userHasAccessToInventory"
          :hasCgCredits="!hideCgCredits"
          @numberOfOrders="(val) => hasOrders = !!val"
        />
      </div>
    </v-row>
    <welcome-modal />
  </v-container>
</template>

<script>
import userName from '@/mixins/userName'
import subAccount from '@/mixins/subAccount'
import superAdmin from '@/mixins/superAdmin'
import adminAccount from '@/mixins/adminAccount'
import userIsCgElite from '@/mixins/userIsCgElite'
import masterAccount from '@/mixins/masterAccount'
import marketplaceUser from '@/mixins/marketplaceUser'
import userHasAccessToApproveOrders from '@/mixins/userHasAccessToApproveOrders'

import TheInventory from '@/components/dashboard/TheInventory.vue'
import TheAccountInfo from '@/components/dashboard/TheAccountInfo.vue'
import TheAccountOwner from '@/components/dashboard/TheAccountOwner.vue'
import TheAccountAdmin from '@/components/dashboard/TheAccountAdmin.vue'
import TheRecentOrders from '@/components/dashboard/TheRecentOrders.vue'
import TheAccountStatus from '@/components/dashboard/TheAccountStatus.vue'
import TheApprovalOrders from '@/components/dashboard/TheApprovalOrders.vue'
import TheCgCreditsBalance from '@/components/dashboard/TheCgCreditsBalance.vue'
import TheActiveEgiftOrders from '@/components/dashboard/TheActiveEgiftOrders.vue'
import TheRecentThankYouNotes from '@/components/dashboard/TheRecentThankYouNotes.vue'

import WelcomeModal from '@/components/WelcomeModal.vue'

export default {
  name: 'Dashboard',
  components: {
    TheInventory,
    TheAccountInfo,
    TheAccountAdmin,
    TheAccountOwner,
    TheRecentOrders,
    TheAccountStatus,
    TheApprovalOrders,
    TheCgCreditsBalance,
    TheActiveEgiftOrders,
    TheRecentThankYouNotes,
    WelcomeModal,
  },
  mixins: [
    userName,
    subAccount,
    superAdmin,
    adminAccount,
    masterAccount,
    userIsCgElite,
    marketplaceUser,
    userHasAccessToApproveOrders,
  ],
  data: () => ({
    showAccountInfo: false,
    hideCgCredits: false,
    hasCgCredits: false,
    hasOrders: false,
  }),
  computed: {
    userHasAccessToInventory () {
      return this.$store.state.header?.headerData?.menus?.myInventory ?? false
    }
  }
}
</script>

<style lang="scss" scoped>
.account-dashboard {
  &__title {
    font-family: 'Lato-Light', sans-serif;
    font-size: 40px;
    line-height: 65px;
    color: #000;
  }

  & > .row {
    gap: 30px;
  }

  &__grid {
    display: grid;
    grid-template-rows: auto;
    gap: 30px;
    grid-template-columns: repeat(1, 380px);
  }

  @media (min-width: 960px) {
    max-width: 790px;

    &__grid {
      grid-template-columns: repeat(2, 380px);
    }
  }

  @media (min-width: 1264px) {
    max-width: 1200px;

    &__grid {
      grid-template-columns: repeat(3, 380px);
    }    
  }
}
</style>
