import getFormDefaultState from './formDefaultState'

import uniqueWith from 'lodash/uniqWith'

export default {
  // source
  setSourceLoading (state, val = false) {
    state.source.loading = val
  },
  setSourceCollections (state, val = []) {
    state.source.collections = val
  },
  setSourceFavorites (state, val = []) {
    state.source.favorites = val
  },
  setSourceInventory (state, val = []) {
    state.source.inventory = val
  },
  // campaigns names
  setAlreadyUsedNames (state, val = []) {
    state.alreadyUsedNames = val
  },
  // user logos
  setUserLogos (state, val = []) {
    state.userLogos = val
  },
  addNewLogo (state, newLogo) {
    state.userLogos.push(newLogo)
  },
  // campaigns tags
  setCampaignsTags (state, val = []) {
    state.campaignsTags = val
  },
  addCampaignTag (state, val) {
    state.campaignsTags.push(val)
  },
  // source breadcrumbs
  setBreadcrumbs (state, val = []) {
    state.breadcrumbs = val
  },
  // form
  setCampaignName (state, val) {
    state.form.name = val
  },
  addNewSelectedProducts (state, newProducts = []) {
    // remove duplicated products
    const uniqueNewProducts = uniqueWith(newProducts, (a, b) => a.id === b.id)
    const currentlySelectedProducts = structuredClone(state.form.selectedProducts)
    const currentProductsIds = new Set(currentlySelectedProducts.map((product) => product.id))
    // remove duplicated products
    const mergedProducts = [
      ...currentlySelectedProducts,
      ...uniqueNewProducts.filter((product) => !currentProductsIds.has(product.id))
    ]

    state.form.selectedProducts = mergedProducts
  },
  removeSelectedProduct (state, id) {
    const { selectedProducts } = state.form
    state.form.selectedProducts = selectedProducts.filter((product) => product.id !== id)
  },
  removeSelectedProductsById (state, ids) {
    const idsDirectory = new Set(ids)

    state.form.selectedProducts = state.form.selectedProducts
      .filter((product) => !idsDirectory.has(product.id))
  },
  removeAllSelectedProducts (state) {
    state.form.selectedProducts = []
  },
  setGreetingMessageTitle (state, val) {
    state.form.greetingMessage.title = val
  },
  setGreetingMessageContent (state, val) {
    state.form.greetingMessage.content = val
  },
  setGreetingMessageFooter (state, val) {
    state.form.greetingMessage.footer = val
  },
  setGreetingMessageLogo (state, val) {
    state.form.greetingMessage.logo = val
  },
  setDisplayName (state, val) {
    state.form.displayName = val
  },
  setIsActive (state, val = true) {
    state.form.isActive = val
  },
  setTags (state, val = []) {
    state.form.tags = val
  },
  // advance options
  setExpirationDays (state, val = 90) {
    state.form.advancedOptions.expirationDays = val
  },
  setEmailSubjectLine (state, val) {
    state.form.advancedOptions.emailSubjectLine = val
  },
  setVideoGreeting (state,  val) {
    state.form.advancedOptions.videoGreeting = val
  },
  setAllowExchange (state, val = false) {
    state.form.advancedOptions.allowExchange = val
  },
  setDisableThankYouNotes (state, val = false) {
    state.form.advancedOptions.disableThankYouNotes = val
  },
  setDisableReminderEmails (state, val = false) {
    state.form.advancedOptions.disableReminderEmails = val
  },
  setAddSuccessButton (state, val = false) {
    state.form.advancedOptions.successButton.addButton = val
  },
  setSuccessButtonURL (state, val) {
    state.form.advancedOptions.successButton.buttonURL = val
  },
  setSuccessButtonLabel (state, val) {
    state.form.advancedOptions.successButton.buttonLabel = val
  },
  // clear
  clearFormState (state) {
    localStorage.removeItem('createCampaignData')

    state.form = {
      ...getFormDefaultState(),
    }
  },
}
