import Vue from 'vue'
import { reSendActivationEmail } from '@/services/authService';
export default {
  bind(el, binding) {
    const resendButton = el.querySelector('#resend_email');

    if (resendButton) {
      const handleClick = async () => {
        try {
          const email = binding.value;
          await reSendActivationEmail(email)
          .then(() => {
            Vue.$cgToast.success('Activation Email Sent')
          })
          .catch((e) => {
            Vue.$cgToast.error(e, { html: true })
          });
        } catch (err) {
          console.error(err);
        }
      };

      el.__handleClick = handleClick;
      resendButton.addEventListener('click', handleClick);
    }
  },

  unbind(el) {
    const resendButton = el.querySelector('#resend_email');
    if (resendButton && el.__handleClick) {
      resendButton.removeEventListener('click', el.__handleClick);
      delete el.__handleClick;
    }
  }
};