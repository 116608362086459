<template>
  <div>
    <header
      :class="{ 'header--is-scrolled': windowScrollY > 0 }"
    >
      <div
        role="button"
        aria-label="Go to previous step"
        class="create-campaign__header-icon"
        :disabled="currentStepIndex === 0"
        :tabindex="currentStepIndex === 0 ? -1 : 0"
        @keypress.enter="onBackButton"
        @keypress.space="onBackButton"
        @click="onBackButton"
      >
        <icons-back color="currentColor" />
      </div>

      <div
        class="create-campaign__header-steps"
        :style="`--header-step-width: ${stepWidthHelper}px`"
      >
        <router-link
          v-for="(step, index) in steps"
          :key="step.id"
          :to="{ name: step.routerName, params: $route.params }"
          :aria-disabled="farthestStepHelper < index ? 'true' : 'false'"
          :disabled="farthestStepHelper < index"
          :tabindex="farthestStepHelper < index ? -1 : 0"
          class="create-campaign__header-step"
          :class="{ 'create-campaign__header-step--active': farthestStepHelper >= index }"
        >
          <div
            class="create-campaign__header-step-index"
            :class="{ 'create-campaign__header-step-index--check': currentStepIndex > index }"
          >
            <svg
              v-if="currentStepIndex > index"
              width="17"
              height="12"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3554 0.75645L6.95182 8.25645L2.6661 3.87966C2.20455 3.41072 1.45023 3.40472
                  0.981283 3.86627C0.512334 4.32782 0.506337 5.08214 0.96789 5.55109L6.12682
                  10.7904C6.20708 10.8605 6.29736 10.9183 6.39468 10.9618C6.43046 10.9934 6.47005
                  11.0204 6.51253 11.0422C6.65531 11.1011 6.80808 11.132 6.96253 11.1332C7.11704
                  11.1325 7.26991 11.1016 7.41253 11.0422C7.46391 11.015 7.51238 10.9827 7.55718
                  10.9457C7.64439 10.9055 7.72554 10.8532 7.79825 10.7904L16.0536 2.43324C16.3778
                  2.135 16.5115 1.68257 16.4016 1.25601C16.2917 0.829443 15.956 0.497965 15.5281
                  0.393494C15.1002 0.289023 14.6495 0.428513 14.3554 0.75645Z"
                fill="#fff"
              />
            </svg>

            <template v-else>{{ index + 1 }}</template>
          </div>

          <div class="create-campaign__header-step-info">
            <span>{{ step.name }}</span>
            <span v-if="step.subText">{{ step.subText }}</span>
          </div>
        </router-link>
      </div>

      <div class="create-campaign__header-icons">
        <div
          v-tooltip="{
            content: 'Campaign gift options',
            ...tooltipProps,
          }"
        >
          <header-gifts-icon :number-of-products="numberOfSelectedProducts" />
        </div>

        <div
          role="button"
          aria-label="FAQ"
          v-tooltip="{
            content: 'FAQ',
            ...tooltipProps,
          }"
          class="create-campaign__header-icon"
          tabindex="0"
          @keypress.enter="showFaqPanel = true"
          @keypress.space="showFaqPanel = true"
          @click="showFaqPanel = true"
        >
          <icons-question color="currentColor" :width="34" :height="34" />
        </div>

        <div
          role="button"
          aria-label="Back to my campaigns"
          class="create-campaign__header-icon"
          tabindex="0"
          @keypress.enter="tryToGetBackToMyCampaigns"
          @keypress.space="tryToGetBackToMyCampaigns"
          @click="tryToGetBackToMyCampaigns"
        >
          <icons-close color="currentColor" />
        </div>
      </div>
    </header>

    <div class="create-campaign__header-mobile-progress" :style="`--progress-value: ${mobileProgressValue}%;`" />

    <div
      v-if="showBreadcrumbs"
      class="create-campaign__breadcrumbs"
    >
      <router-link
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.routeName"
        :to="{ name: breadcrumb.routeName, params: $route.params }"
      >
        {{ breadcrumb.title }}
      </router-link>
    </div>

    <div class="create-campaign__content">
      <router-view />
    </div>

    <create-campaign-faq-panel v-model="showFaqPanel" />

    <create-campaign-close-modal v-model="showCloseModal" />

    <welcome-modal />
  </div>
</template>

<script>
import userName from '@/mixins/userName'
import onExitPage from '@/components/createCampaign/mixins/onExitPage'
import { createNamespacedHelpers } from 'vuex'

import HeaderGiftsIcon from '@/components/createCampaign/CreateCampaignHeaderGiftsIcon.vue'
import CreateCampaignFaqPanel from '@/components/createCampaign/CreateCampaignFaqPanel.vue'
import CreateCampaignCloseModal from '@/components/createCampaign/CreateCampaignCloseModal.vue'

import WelcomeModal from '@/components/WelcomeModal.vue'

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaign',
  mixins: [
    userName,
    onExitPage,
  ],
  components: {
    HeaderGiftsIcon,
    CreateCampaignFaqPanel,
    CreateCampaignCloseModal,
    WelcomeModal,
  },
  data: () => ({
    stepWidthHelper: 196,
    windowScrollY: 0,
    showFaqPanel: false,
    showCloseModal: false,
    farthestStepHelper: 0,
    tooltipProps: {
      placement: 'bottom',
      trigger: 'hover',
      classes: 'create-campaign__icon-tooltip',
      offset: 0,
    }
  }),
  computed: {
    ...mapState([
      'form',
      'breadcrumbs',
    ]),
    ...mapGetters([
      'greetingMessage',
      'numberOfSelectedProducts',
      'priceRangeOfSelectedProducts',
    ]),
    campaignName () { return this.form.name ?? null },
    selectedGiftsSubText () {
      const { numberOfSelectedProducts, priceRangeOfSelectedProducts } = this

      if (numberOfSelectedProducts > 0) {
        return `${numberOfSelectedProducts} gifts | ${priceRangeOfSelectedProducts}`
      }

      return null
    },
    steps () {
      const { campaignName, selectedGiftsSubText, greetingMessage } = this

      const result = [
        {
          name: 'Name Campaign',
          subText: campaignName,
          id: 'name-campaign',
          routerName: 'CreateCampaignName',
          routes: ['CreateCampaignName'],
        },
        {
          name: 'Select Gifts',
          subText: selectedGiftsSubText,
          id: 'select-gifts',
          routerName: 'CreateCampaignSourceReview',
          routes: [
            'CreateCampaignSource',
            'CreateCampaignSourceCollections',
            'CreateCampaignSourceCollection',
            'CreateCampaignSourceInventory',
            'CreateCampaignSourceFavorites',
            'CreateCampaignSourceReview',
          ],
        },
        {
          name: 'Greeting',
          subText: greetingMessage,
          id: 'greeting-message',
          routerName: 'CreateCampaignGreeting',
          routes: ['CreateCampaignGreeting'],
        },
        {
          name: 'Review campaign',
          subText: null,
          id: 'review-campaign',
          routerName: 'CreateCampaignReview',
          routes: ['CreateCampaignReview'],
        },
      ];

      return result;
    },
    currentRouteName () {
      return this.$route?.name
    },
    currentStepIndex () {
      const { steps, currentRouteName } = this;
      return steps?.findIndex((step) => step.routes.includes(currentRouteName)) ?? 0
    },
    mobileProgressValue () {
      const { steps: { length }, currentStepIndex } = this;
      const diff = length - (currentStepIndex + 1)

      return 100 - (diff / length * 100)
    },
    showBreadcrumbs () {
      const { currentRouteName } = this
      const pagesWithBreadcrumbs = [
        'CreateCampaignSourceCollections',
        'CreateCampaignSourceCollection',
        'CreateCampaignSourceInventory',
        'CreateCampaignSourceFavorites',
      ]

      return pagesWithBreadcrumbs.includes(currentRouteName)
        && this.$vuetify.breakpoint.lgAndUp
    },
    campaignId () {
      return this.$route.params.campaignId ?? 'create'
    },
  },
  watch: {
    currentStepIndex: {
      immediate: true,
      handler: function (val) {
        const { farthestStepHelper } = this
        if (val > farthestStepHelper) { this.farthestStepHelper = val }
      }
    },
    currentRouteName: function () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    form: {
      deep: true,
      handler: function (formData) {
        localStorage.setItem('createCampaignData', JSON.stringify(formData))
      }
    }
  },
  created () {
    const { userFullName, campaignId, form: { displayName } } = this

    if (campaignId === 'create') {
      this.getSourceItems()
      this.setGreetingMessage()

      if (userFullName && !displayName) { this.setDisplayName(userFullName) }
      this.storeFormData()
    } else {
      this.loadCampaignData(campaignId)
        .then(() => {
          this.storeFormData()
        })
    }

    this.getCampaignNames()
    this.getUserLogos()
  },
  mounted () {
    const headerStepInfo = this.$el.querySelector('.create-campaign__header-step-info')
    if (headerStepInfo) { this.stepWidthHelper = headerStepInfo.offsetWidth }
    window.addEventListener('scroll', this.onScroll)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('app/setHideHeader', true)
      vm.$store.commit('app/setHideUserMenu', true)
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('app/setHideHeader', false)
    this.$store.commit('app/setHideUserMenu', false)
    next()
  },
  methods: {
    ...mapMutations([
      'setDisplayName',
      'setGreetingMessageTitle',
    ]),
    ...mapActions([
      'getUserLogos',
      'getSourceItems',
      'getCampaignNames',
      'loadCampaignData',
    ]),
    onScroll () {
      this.windowScrollY = window?.scrollY ?? 0
    },
    // you can't just handle campaign products source and rest with "$router.go(-1)"
    // because it will create a loop in some cases
    onBackButton () {
      const { currentRouteName } = this

      switch (currentRouteName) {
        case 'CreateCampaignReview':
          this.$router.push({ name: 'CreateCampaignGreeting', params: this.$route.params })
          break;
        case 'CreateCampaignGreeting':
          this.$router.push({ name: 'CreateCampaignSourceReview', params: this.$route.params })
          break;
        case 'CreateCampaignSourceReview':
        case 'CreateCampaignSourceCollections':
        case 'CreateCampaignSourceFavorites':
        case 'CreateCampaignSourceInventory':
          this.$router.push({ name: 'CreateCampaignSource', params: this.$route.params })
          break;
        case 'CreateCampaignSourceCollection':
          this.$router.push({ name: 'CreateCampaignSourceCollections', params: this.$route.params })
          break;
        case 'CreateCampaignSource':
          this.$router.push({ name: 'CreateCampaignName', params: this.$route.params })
          break;
        default:
          break;
      }
    },
    setGreetingMessage () {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.setGreetingMessageTitle('Hi @First_name ')
      }
    },
    storeFormData () {
      const currentForm = structuredClone(this.form)
      window.formsComparator.addItemToStore('create-campaign', currentForm)
    },
    tryToGetBackToMyCampaigns () {
      const currentForm = structuredClone(this.form)
      const result = window.formsComparator.compare('create-campaign', currentForm)

      if (!result) {
        this.showCloseModal = true
      } else {
        this.onExitPage()
      }
    }
  }
}
</script>

<style lang="scss">
.create-campaign {
  &__products-wrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 42px 42px;
    background-color: #FAFAFA;
    border-radius: 24px;
    width: 100%;
    gap: 60px;

    @media screen and (max-width: 430px) {
      gap: 30px;
      padding: 32px 16px 42px;
    }
  }

  &__products-actions {
    gap: 44px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    top: 104px;
    z-index: 2;
    background-color: #FAFAFA;
    padding: 8px 0;

    & button {
      border-radius: 100px;
    }

    @media screen and (max-width: 430px) {
      flex-direction: column;
      top: 54px;
      padding: 12px 0 8px;

      & button {
        display: none;
      }
    }

    &--elevation {
      box-shadow: 0px 4px 4px -2px #00000014;
    }
  }

  &__products-filters {
    gap: 16px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;

    & > span {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      color: #6E50FF;
      line-height: 34px;
      font-size: 14px;
      font-family: 'Lato-Regular', sans-serif;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #95979D;
      }

      &[aria-disabled="true"] {
        color: #62646A;
        pointer-events: none;
      }
    }

    @media screen and (max-width: 430px) {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      & > .v-input {
        width: 100% !important;
      }
    }
  }

  &__products-buttons-group {
    gap: 16px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;

    & > span {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      color: #6E50FF;
      line-height: 34px;
      font-size: 14px;
      font-family: 'Lato-Regular', sans-serif;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #95979D;
      }

      &[aria-disabled="true"] {
        color: #62646A;
        pointer-events: none;
      }

      @media screen and (max-width: 430px) {
        &:first-child::before {
          content: none;
        }
      }
    }
  }

  &__button-badge {
    background-color: #fff;
    border-radius: 48px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    height: 22px;
    color: #222325;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
  }

  &__products-grid {
    display: grid;
    gap: 40px 20px;
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 1400px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 430px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 8px;
    }
  }

  &__icon-tooltip {
    padding: 6px 10px;
    background-color: #222325;
    border-radius: 4px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
  }

  &__sticky-button {
    display: none;
    z-index: -1;
    position: sticky;
    bottom: 0;
    padding: 16px 10px;
    background-color: #fff;
    width: 100%;

    & > button,
    & > a {
      border-radius: 100px;
    }

    @media screen and (max-width: 430px) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      z-index: 6;
    }
  }
}
</style>

<style lang="scss" scoped>
header {
  top: 0;
  gap: 40px;
  z-index: 6;
  height: 104px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  position: sticky;
  padding: 22px 40px;
  background-color: #fff;
  justify-content: space-between;

  &.header--is-scrolled {
    box-shadow: 0px 4px 4px 0px #00000014;
  }

  @media screen and (max-width: 430px) {
    padding: 10px 8px;
    height: unset;

    box-shadow: none !important;
  }
}

.create-campaign {
  &__content {
    display: flex;
    padding: 60px;
    max-width: 1600px;
    margin-inline: auto;
    justify-content: center;

    &::v-deep h1 {
      color: #000;
      font-family: 'Lato-Light', sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      line-height: 65px;
    }

    &::v-deep .cg-loader {
      padding: 100px 0;
    }

    @media screen and (max-width: 430px) {
      padding: 40px 16px;

      &::v-deep h1 {
        font-size: 32px;
        font-weight: 300;
        line-height: 38px;
        text-align: center;
      }

      &:has(.create-campaign-source-inventory),
      &:has(.create-campaign-source-collection),
      &:has(.create-campaign-source-favorites) {
        padding: 40px 0;
      }

      &:has(.create-campaign-greeting-message),
      &:has(.create-campaign-source-review) {
        padding: 40px 0 0;
      }
    }
  }

  &__breadcrumbs {
    gap: 4px;
    display: flex;
    padding: 16px 60px;
    align-items: center;
    flex-direction: row;

    & > a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 4px;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.16px;
      color: #222325;
      border-bottom: 1px solid transparent;

      &:not(:last-child)::after {
        content: '\203A';
        font-size: 19px;
      }

      &:last-child {
        color: #62646A;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        border-color: currentColor;
      }
    }

    & ~ .create-campaign__content {
      padding: 40px 60px;
    }

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  &__header-icons {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    cursor: pointer;
    color: #222325;
    aspect-ratio: 1 / 1;
    transition: color ease-out 0.2s;

    &[disabled] {
      cursor: default;
      color: #62646A;
      opacity: 0;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      color: #22232599;
    }

    @media screen and (max-width: 430px) {
      width: 30px;
    }
  }

  &__header-steps {
    gap: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 6px;
    width: 960px;

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  &__header-step {
    gap: 8px;
    display: flex;
    flex: 1 1 0px;
    color: #95979D;
    cursor: default;
    pointer-events: none;
    flex-direction: row;

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    &--active {
      pointer-events: all;
      cursor: pointer;
      color: #222325;
    }
  }

  &__header-step-index {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    position: relative;
    border: 2px solid currentColor;

    color: currentColor;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 16px;

    &--check {
      background-color: currentColor;
    }
  }

  &__header-step-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      margin-top: 15px;
      margin: 15px 0 3px;
      background-color: currentColor;
    }

    & > span {
      &:nth-child(1) {
        font-family: 'Lato-Bold', sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        color: currentColor;
      }

      &:nth-child(2) {
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #6E50FF;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: var(--header-step-width, 196px);
      }
    }
  }

  &__header-mobile-progress {
    display: none;
    width: 100%;
    height: 6px;
    position: sticky;
    top: 54px;
    background-color: #F4F4F4;
    z-index: 6;

    &::after {
      content: '';
      height: 100%;
      background: linear-gradient(90deg, #B0A0FF 0%, #6E51FF 100%);
      width: var(--progress-value);
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      transition: width ease-in-out 0.2s;
    }

    @media screen and (max-width: 430px) {
      display: flex;
    }
  }
}

.blink-animation {
  animation: blink 0.6s steps(3, start) infinite;
  -webkit-animation: blink 0.6s steps(3, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
