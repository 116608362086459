<template>
  <div class="account-auth-page__container">
    <cg-loader v-if="loading" style="height: unset" />

    <div v-else class="account-auth-page__form">
      <h1>Welcome back</h1>

      <span class="login-page-text login-page-text--hide-on-mobile">
        Don't have an account?
        <router-link
          :to="{
            name: 'RegisterPage',
            query: { ...this.$route.query }
          }"
        >
          Sign up
        </router-link>
      </span>

      <cg-button
        :href="googleURL"
        outlined
        color="#4285F4"
        text-color="#4285F4"
        class="account-auth-page__google-btn"
        @click="onGoogleSSO"
      >
        <template #prepend-icon>
          <img src="https://cf.corporategift.com/images/google-logo.png" alt="" width="22" height="22" class="mr-3">
        </template>
        Continue with Google
      </cg-button>

      <cg-button outlined text-color="#000" :to="{ name: 'SSOPage', query: { ...this.$route.query } }">
        Log with SSO
      </cg-button>

      <div class="account-auth-page__divider">
        Or:
      </div>

      <cg-form ref="form" @submit="onSubmit">
        <cg-alert v-if="loginErrorMsg" v-check-resend-email="form.email" type="error">
         <span v-html="loginErrorMsg"></span>
        </cg-alert>
        <cg-input
          v-model="form.email"
          type="email"
          :validation="[required(), email()]"
          aria-autocomplete="none"
          placeholder="Email"
        >
          <template #label>Email address</template>
        </cg-input>

        <cg-input
          v-model="form.password"
          :type="showPassword ? 'text' : 'password'"
          :validation="[required()]"
          aria-autocomplete="none"
          placeholder="Enter your password"
        >
          <template #label>
            <span class="account-auth-page__label">
              Enter Password

              <router-link :to="{ name: 'ForgotPasswordPage' }">Forgot your password?</router-link>
            </span>
          </template>

          <template #append-icon>
            <div
              class="account-auth-page__icon"
              @click.prevent.stop="showPassword = !showPassword"
            >
              <icons-eye v-if="showPassword" black />
              <icons-eye-crossed v-else />
            </div>
          </template>
        </cg-input>

        <cg-checkbox v-model="form.keepUserLoggedIn">Keep me logged in</cg-checkbox>

        <span class="login-page-text login-page-text--show-on-mobile">
          Don't have an account?
          <router-link
            :to="{
              name: 'RegisterPage',
              query: { ...this.$route.query }
            }"
          >
            Sign up
          </router-link>
        </span>

        <cg-button type="submit" :disabled="!form.password">Log in</cg-button>
      </cg-form>
    </div>
  </div>
</template>

<script>
import setCookie from '@/utils/setCookie'
import syncEmail from '@/mixins/auth/syncEmail'
import { signInAction } from '@/services/authService'
import authRedirectModule from '@/mixins/auth/authRedirectModule'
import { required, email } from '@corporategift/design-system/validations'
import checkResendEmail from '@/directives/checkResendEmail'

import { CgAlert, CgButton, CgForm, CgCheckbox, CgInput, CgLoader } from '@corporategift/design-system'

export default {
  name: 'LoginPage',
  mixins: [
    syncEmail,
    authRedirectModule,
  ],
  directives: {
    checkResendEmail
  },
  components: {
    CgAlert,
    CgCheckbox,
    CgButton,
    CgLoader,
    CgInput,
    CgForm,
  },
  data: () => ({
    form: {
      email: '',
      password: '',
      keepUserLoggedIn: true,
    },
    loading: false,
    showPassword: false,
    loginErrorMsg: null
  }),
  computed: {
    googleURL () {
      return `${process.env.VUE_APP_API_URL}/api/customer/google-sign-in`
    },
  },
  methods: {
    required,
    email,
    onSubmit () {
      if (this.$refs.form.validate()) {
        const { form } = this

        this.loading = true
        signInAction(form)
          .then(({ token, isVendor }) => {
            this.loginErrorMsg = null
            setCookie(token, 14, 'cgToken')
            setCookie(Number(isVendor), 14, 'cgUserIsVendor')
            this.$store.commit('headerAuth/setToken', token)

            this.redirectLoggedInUser()
          })
          .catch((e) => {
            this.loginErrorMsg = e
            this.loading = false
          })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/css/auth-page.scss";

.login-page-text {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #000;

  & > a {
    color: #6E50FF;
    cursor: pointer;
  }

  &--hide-on-mobile {
    display: block;

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  &--show-on-mobile {
    display: none;

    @media screen and (max-width: 430px) {
      display: block;
    }
  }
}
</style>
